import { Link } from 'gatsby'

import { ReactComponent as ArrowIcon } from '~/assets/images/icons/arrow-left-white.svg'
import clutchRating from '~/assets/images/icons/clutch-rating.svg?file'
import * as containerStylesProjectColor from '~/styles/modules/ProjectsColors.module.scss'
import { cx } from '~/utils'

import * as containerStyles from './CaseStudiesCards.module.scss'

const CaseStudiesCards = ({ dataContent }) => (
  <div className="container container-lg">
    <div className="row">
      {dataContent.map(
        ({
          title,
          description,
          color,
          reverse,
          image,
          url,
          infoLeftHead,
          infoLeftText,
          infoRightHead,
          infoRightText,
          stack,
        }) => (
          <div className="col-lg-12" key={color}>
            <div
              className={cx(
                containerStyles.caseStudies,
                containerStyles[`caseStudies__${color}`],
                containerStylesProjectColor[`${color}`],
                reverse && containerStyles.caseStudies__rowReverse,
              )}
            >
              <Link
                to={`/case-studies/${url}/`}
                className={containerStyles.caseStudies__link}
              >
                {title}
              </Link>

              <div className={containerStyles.caseStudies__content}>
                <a
                  target="_blank"
                  href="https://clutch.co/profile/codica#reviews"
                  className={containerStyles.caseStudies__rating}
                >
                  <img
                    src={clutchRating}
                    alt="Clutch Rating | Codica"
                    title="Codica Clutch Rating"
                    width="189px"
                    height="26px"
                    loading="lazy"
                  />
                </a>
                <h2 className={containerStyles.caseStudies__title}>{title}</h2>
                <p className={containerStyles.caseStudies__description}>
                  {description}
                </p>

                <div className={containerStyles.caseStudies__info}>
                  <div className={containerStyles.caseStudies__infoWrapper}>
                    <div className={containerStyles.caseStudies__infoHead}>
                      {infoLeftHead}
                    </div>
                    <div className={containerStyles.caseStudies__infoSubhead}>
                      {infoLeftText}
                    </div>
                  </div>

                  <div className={containerStyles.caseStudies__infoWrapper}>
                    <div className={containerStyles.caseStudies__infoHead}>
                      {infoRightHead}
                    </div>
                    <div className={containerStyles.caseStudies__infoSubhead}>
                      {infoRightText}
                    </div>
                  </div>
                </div>

                <div className={containerStyles.caseStudies__techStackWrapper}>
                  {stack.map((icon) => (
                    <div
                      key={icon}
                      className={cx(
                        containerStyles.caseStudies__techStack,
                        containerStyles[`caseStudies__techStack_${icon}`],
                      )}
                    />
                  ))}
                </div>

                <Link
                  to={`/case-studies/${url}/`}
                  className={containerStyles.caseStudies__btn}
                >
                  See case study
                  <ArrowIcon />
                </Link>
              </div>

              <div className={containerStyles.caseStudies__image}>{image}</div>
            </div>
          </div>
        ),
      )}
    </div>
  </div>
)

export default CaseStudiesCards
