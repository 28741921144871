// extracted by mini-css-extract-plugin
export var africarColor = "c8_yn";
export var ammoColor = "c8_x4";
export var babelColor = "c8_ym";
export var cakerColor = "c8_x7";
export var chatticColor = "c8_x5";
export var danColor = "c8_x6";
export var dumaColor = "c8_yg";
export var dvColor = "c8_yq";
export var impactColor = "c8_yb";
export var ixColor = "c8_yr";
export var malleniColor = "c8_x9";
export var pmkColor = "c8_yl";
export var refColor = "c8_yh";
export var rewColor = "c8_yj";
export var timebotColor = "c8_yd";
export var tradeaboatColor = "c8_yf";
export var tradervsColor = "c8_yc";
export var variaColor = "c8_x8";
export var wownerColor = "c8_yk";
export var xpcColor = "c8_yp";